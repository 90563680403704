// #region Global Imports
import * as React from "react";
import App from "next/app";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import withRedux from "next-redux-wrapper";
import Nookies from "nookies";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import relativeTime from "dayjs/plugin/relativeTime";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import Head from "next/head";
import { UAParser } from "ua-parser-js";
import "react-credit-cards/es/styles-compiled.css";
import * as Sentry from "@sentry/browser";
import { persistStore } from "redux-persist";
// #endregion Global Imports

// #region Local Imports
import { appWithTranslation, withTranslation } from "@Server/i18n";
import { pubSub } from "@Definitions/PubSub";
import {
    ActionConsts,
    Redirect,
    FetchDashboardData,
    theme,
    DeviceContext,
    attachRouterListener,
    RedirectIfNotAuthorized,
} from "@Definitions";
import { AppWithStore, AppContextWithStore, IAppState } from "@Interfaces";
import {
    PusherComponent,
    PutMeUpHere,
    Header,
    Modal,
    RegisterBox,
    IntervalComponent,
    NoSSR,
} from "@Components";
import { Analytics, FacebookService, ProfileService } from "@Services";
import { makeStore } from "@Redux";

import "@Static/css/main.scss";
// #endregion Local Imports

Sentry.init({ dsn: "https://33a0e0ed64264b6fa896c88d295eb00c@o245899.ingest.sentry.io/5198322" });
dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

class WebApp extends App<AppWithStore> {
    static async getInitialProps({ Component, ctx }: AppContextWithStore) {
        const { account } = Nookies.get(ctx);

        if (account) {
            const parsedAccount = JSON.parse(account);

            ctx.store?.dispatch?.({
                payload: parsedAccount,
                type: ActionConsts.Account.Login,
            });

            if (ctx.pathname === "/") {
                Redirect(ctx, "dashboard", "replace");
            }
        }

        const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};

        const deviceType =
            ctx.req !== undefined
                ? new UAParser(ctx.req.headers["user-agent"]).getDevice().type
                : "";

        return { pageProps, deviceType, account };
    }

    state: IAppState = {
        registerModalIsOpen: false,
        openRegisterModalUnsubscription: undefined,
        accountCancellationUnsubscribe: undefined,
    };

    async componentDidMount() {
        // init analytics service
        Analytics.init();

        const [dispatch, cookie] = [this.props.store?.dispatch, Nookies.get()];

        dayjs.locale("tr");

        if (dispatch) {
            attachRouterListener(dispatch);

            if (cookie.account) {
                FetchDashboardData(dispatch);

                const canSendStartedSessionResponse = await ProfileService.CanSendStartedSession();
                if (canSendStartedSessionResponse.CanSend) {
                    Analytics.event("Started Session");
                }
            }
        }

        FacebookService.init();

        const openRegisterModalSubscription = pubSub.subscribe("openRegisterModal", () => {
            this.setState({ registerModalIsOpen: true });
        });

        const accountCancellationSubscribe = pubSub.subscribe("accountCancellation", () => {
            RedirectIfNotAuthorized();
        });

        this.setState(state => ({
            ...state,
            openRegisterModalUnsubscription: openRegisterModalSubscription.unsubscribe,
            accountCancellationUnsubscribe: accountCancellationSubscribe.unsubscribe,
        }));
    }

    componentWillUnmount() {
        if (this.state.openRegisterModalUnsubscription)
            this.state.openRegisterModalUnsubscription();
        if (this.state.accountCancellationUnsubscribe) this.state.accountCancellationUnsubscribe();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo.componentStack);
            });

            Sentry.captureException(error);
        });

        super.componentDidCatch(error, errorInfo);
    }

    render() {
        const { Component, pageProps, store, deviceType, account, t, router } = this.props;
        // const persistor = persistStore(store);
        persistStore(store);
        return (
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <DeviceContext.Provider value={deviceType}>
                        <>
                            <Head>
                                <title>
                                    Siberalem | Türkiye&rsquo;nin En Güvenilir Arkadaşlık ve Evlilik Sitesi
                                </title>
                                <meta
                                    name="viewport"
                                    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                                />
                                <meta
                                    name="description"
                                    content="Türkiye’nin güvenilir arkadaşlık ve evlilik sitesi Siberalem, hayalinizdeki ilişkiye ulaşmanız için doğru adres."
                                />
                                <link
                                    rel="canonical"
                                    href={`https://www.siberalem.com${router.asPath}`}
                                />
                            </Head>
                            <script
                                id="facebook-pixel"
                                dangerouslySetInnerHTML={{
                                    __html: `
                                        !function(f,b,e,v,n,t,s){
                                        if(f.fbq)return;
                                        n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;
                                        n.version='2.0';n.queue=[];
                                        t=b.createElement(e);t.async=!0;t.src=v;
                                        s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)
                                        }(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
                                        fbq('init', '1259544881995512');
                                        fbq('track', 'PageView');
                                    `,
                                }}
                            />
                            <noscript>
                                <img
                                    height="1"
                                    width="1"
                                    style={{ display: "none" }}
                                    src="https://www.facebook.com/tr?id=1259544881995512&ev=PageView&noscript=1"
                                />
                            </noscript>
                            <script
                                id="tiktok-pixel"
                                dangerouslySetInnerHTML={{
                                    __html: `
                                        !function (w, d, t) {
                                        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],
                                        ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};
                                        for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
                                        ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},
                                        ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};
                                        n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;
                                        e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
                                        ttq.load('CTLDDS3C77U1LI1DDG50');
                                        ttq.page();
                                        }(window, document, 'ttq');
                                    `,
                                }}
                            />
                        </>

                        {account && <Header t={t} />}

                        {account && (
                            <div className="my-5">
                                <PutMeUpHere t={t} />
                            </div>
                        )}

                        <NoSSR>
                            {account && <PusherComponent />}
                            {account && <IntervalComponent />}
                        </NoSSR>

                        <Component {...pageProps} />
                        {this.state.registerModalIsOpen && (
                            <Modal>
                                <RegisterBox
                                    t={t}
                                    onCloseClick={() =>
                                        this.setState({ registerModalIsOpen: false })
                                    }
                                />
                            </Modal>
                        )}
                    </DeviceContext.Provider>
                </ThemeProvider>
            </Provider>
        );
    }
}

export default withRedux(makeStore)(
    appWithTranslation(withTranslation(["common", "dashboard"])(WebApp as any))
);
